import React from "react"
import { Form, Input } from "antd"

const { TextArea } = Input

const ContactForm = ({ form, setForm, handleFormSubmit, id }) => {
	const labelStyle = { fontWeight: 700, fontFamily: "Lato, sans-serif", fontSize: 16 }

	const selectStyle = { fontWeight: 400, color: "#ABB5BE", border: "1px solid #ABB5BE", borderRadius: "8px", padding: 0, fontSize: 16, marginTop: 0 }

	const inputStyle = { ...selectStyle, padding: "5px 10px" }

	const label = text => {
		return <span style={labelStyle}>{text}</span>
	}

	return (
		<Form layout="vertical" onSubmitCapture={handleFormSubmit} id={id}>
			<Form.Item label={label("E-mail")} required style={labelStyle}>
				<Input placeholder="Insira seu e-mail" style={inputStyle} value={form?.email} onChange={e => setForm({ ...form, email: e.target.value })} />
			</Form.Item>

			<Form.Item label={label("Nome completo")} required style={labelStyle}>
				<Input placeholder="Insira seu nome completo" style={inputStyle} value={form?.name} onChange={e => setForm({ ...form, name: e.target.value })} />
			</Form.Item>

			<Form.Item label={label("Telefone")} required style={labelStyle}>
				<Input placeholder="Insira seu telefone com DDD" style={inputStyle} value={form?.phone} onChange={e => setForm({ ...form, phone: e.target.value })} />
			</Form.Item>

			<Form.Item label={label("Mensagem")} required style={labelStyle}>
				<TextArea placeholder="Insira sua mensagem" rows={4} style={inputStyle} value={form?.message} onChange={e => setForm({ ...form, message: e.target.value })} />
			</Form.Item>
		</Form>
	)
}

export default ContactForm
