import React from "react"

// icons
import CalendarIcon from "../../assets/icons/CalendarBlank.svg"
import HandshakeIcon from "../../assets/icons/Handshake.svg"
import TimerIcon from "../../assets/icons/Timer.svg"
import WifiIcon from "../../assets/icons/WifiHigh.svg"

// components
import BenefitCard from "./BenefitCard"

import styles from "./Benefits.module.scss"

const Benefits = () => {
  const heading = {
    strong: "O jeito Empy: ",
    text: "viva o agora, pague no seu tempo"
  }

  const cards = [
    {
      icon: HandshakeIcon,
      title: "Negócio Fechado",
      description: "Crédito rápido e fácil. Falta de crédito não é mais problema. Empy é a solução."
    },
    {
      icon: WifiIcon,
      title: "100% Online",
      description: "Sem filas no banco ou telefone. Processo feito totalmente de casa, pela internet."
    },
    {
      icon: TimerIcon,
      title: "Aprovação Rápida",
      description: "Equipe qualificada. Liberação de crédito de um jeito ágil e flexível."
    },
    {
      icon: CalendarIcon,
      title: "Parcelas Facilitadas",
      description: "Atendimento especial. Crédito com parcelas facilitadas junto aos principais bancos."
    }
  ]

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.heading}>
          <strong>{heading.strong}</strong>
          {heading.text}
        </h2>
        <div className={styles.gridContainer}>
          <div className={styles.cardsContainer}>
            {cards.map(card => {
              return (
                <div className={styles.benefitCard} key={card.title}>
                  <BenefitCard data={card} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Benefits
