import React from "react"

// components
import Button from "../atoms/Button/Button"

const B2BCustomerButton = ({ children, onClick, style }) => {
  return (
    <Button variant="callSecondary" style={{ marginRight: 27, fontSize: "20px", ...style }} onClick={onClick}>
      {children}
    </Button>
  )
}

export default B2BCustomerButton
