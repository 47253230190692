import React from "react"

import styles from "./Button.module.scss"

const Button = ({ children, onClick, style, variant, className, disabled, form }) => {
  var buttonStyle = styles.buyBtn

  if (variant === "loginPrimary") {
    buttonStyle = styles.loginPrimary
  } else if (variant === "loginSecondary") {
    buttonStyle = styles.loginSecondary
  } else if (variant === "callPrimary") {
    buttonStyle = styles.callPrimary
  } else if (variant === "callSecondary") {
    buttonStyle = styles.callSecondary
  } else if (variant === "partnerProduct") {
    buttonStyle = styles.partnerProduct
  } else {
    buttonStyle = styles.buyBtn
  }

  return (
    <button onClick={onClick} disabled={disabled} form={form} className={`${styles.btn} ${buttonStyle} ${className}`} style={style}>
      {children}
    </button>
  )
}

export default Button
