import React, { useState } from "react"

// components
import ServicesB2B from "./ServicesB2B"
import ServicesB2C from "./ServicesB2C"

import styles from "./Services.module.scss"

const Services = () => {
  const [isB2C, setIsB2C] = useState(true)

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>NOSSOS SERVIÇOS</h1>
          <p className={styles.description}>A SOLUÇÃO IDEAL PARA VOCÊ OU PARA SUA EMPRESA</p>
        </div>
        <div className={styles.options}>
          <div
            className={styles.optionContainer}
            onClick={() => {
              setIsB2C(true)
            }}
          >
            <div className={styles.option}>PARA VOCÊ</div>
            {isB2C && <div className={styles.optionSelected} />}
          </div>

          <div
            className={styles.optionContainer}
            onClick={() => {
              setIsB2C(false)
            }}
          >
            <div className={styles.option}>PARA A SUA EMPRESA</div>
            {!isB2C && <div className={styles.optionSelected} />}
          </div>
        </div>
      </div>

      <div className={styles.cardContainer}>
        {isB2C && <ServicesB2C />}
        {!isB2C && <ServicesB2B />}
      </div>
    </div>
  )
}

export default Services
