import React from "react"
import { useAuth0 } from "@auth0/auth0-react"

// components
import Button from "../atoms/Button/Button"

const LogoutButton = ({ children, style }) => {
  const { logout } = useAuth0()

  return (
    <Button variant="loginSecondary" onClick={() => logout()} style={style}>
      {children}
    </Button>
  )
}

export default LogoutButton
