import React from "react"

import styles from "./BenefitCard.module.scss"

const BenefitCard = ({ data }) => {
  const { icon, title, description } = data

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <div className={styles.icon}>
          <img src={icon} alt={icon} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  )
}

export default BenefitCard
