import React from "react"
// import { useAuth0 } from "@auth0/auth0-react"

import {Link} from 'react-router-dom'
// components
import Button from "../atoms/Button/Button"

const LoginButton = ({ style, text }) => {
  // const { loginWithRedirect } = useAuth0()
  return (
    <Link to={{ pathname: "https://app.empy.com.br/" }} target="_blank">
      <Button
      variant="loginSecondary"
      // onClick={() =>
      //   // loginWithRedirect({
        //   //   redirectUri: "https://eager-varahamihira-f77f74.netlify.app/area-do-parceiro/"
        //   // })
        //   loginWithRedirect()
        // }
        style={style}
        >
          {text}
      </Button>
    </Link>
      
  )
}

export default LoginButton
