import React from "react"
import { Link } from "react-router-dom"
import MediaQueries from "../../utils/MediaQueries"

// icons
// import ArrowRight from "../../assets/icons/ArrowRight.svg"

import styles from "./ServicesB2C.module.scss"

const ServicesB2C = () => {
  const { isTabletOrMobile } = MediaQueries()

  return (
    <div className={styles.container}>
      {!isTabletOrMobile && (
        <div className={styles.upperCard}>
          <div className={styles.leftContainer}>
            <h4 className={styles.upperTitle}>EMPRÉSTIMO PESSOAL E CONSIGNADO</h4>
          </div>
          <div className={styles.rightContainer}>
            <p>Crédito rápido e fácil, 100% online, contratado pelo Whatsapp ou telefone.</p>
            <Link to="/cotação">
              <p className={styles.simulation}>
                {/* <span className={styles.simulationText}>Fazer simulação</span> */}
                {/* <img src={ArrowRight} alt="Seta para direita" /> */}
              </p>
            </Link>
          </div>
        </div>
      )}
      <div className={styles.lowerCardsContainer}>
        {isTabletOrMobile && (
          <div className={styles.lowerCard}>
            <div className={styles.lowerTitle}>
              <h4>EMPRÉSTIMO PESSOAL E CONSIGNADO</h4>
            </div>
            <div className={styles.lowerText}>
              <p>Crédito rápido e fácil, 100% online, contratado pelo Whatsapp ou telefone.</p>
              <Link to="/cotação">
                <p className={styles.simulation}>
                  {/* <span className={styles.simulationText}>Fazer simulação</span> */}
                  {/* <img src={ArrowRight} alt="Seta para direita" /> */}
                </p>
              </Link>
            </div>
          </div>
        )}

        <div className={styles.lowerCard}>
          <div className={styles.lowerTitle}>
            <h4>REFINANCIAMENTO</h4>
          </div>
          <div className={styles.lowerText}>
            <p>Facilidades para reorganizar suas finanças. Sem filas.</p>
            <Link to="/cotação">
              <p className={styles.simulation}>
                {/* <span className={styles.simulationText}>Fazer simulação</span> */}
                {/* <img src={ArrowRight} alt="Seta para direita" /> */}
              </p>
            </Link>
          </div>
        </div>

        <div className={styles.lowerCard}>
          <div className={styles.lowerTitle}>
            <h4>PORTABILIDADE DE DÍVIDA</h4>
          </div>
          <div className={styles.lowerText}>
            <p>Reorganize suas contas de forma fácil. Sem filas.</p>
            <Link to="/cotação">
              <p className={styles.simulation}>
                {/* <span className={styles.simulationText}>Fazer simulação</span> */}
                {/* <img src={ArrowRight} alt="Seta para direita" /> */}
              </p>
            </Link>
          </div>
        </div>
        <div className={styles.lowerCard}>
          <div className={styles.lowerTitle}>
            <h4>SAQUE FGTS</h4>
          </div>
          <div className={styles.lowerText}>
            <p>Ajudamos você a acessar seus recursos no FGTS.</p>
            <Link to="/cotação">
              <p className={styles.simulation}>
                {/* <span className={styles.simulationText}>Fazer simulação</span> */}
                {/* <img src={ArrowRight} alt="Seta para direita" /> */}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesB2C
