import React from "react"
import { Link } from "react-router-dom"

// components
import Button from "../atoms/Button/Button"

const CreateAccountButton = ({ style }) => {
  return (
    <Link to={{ pathname: "https://app.empy.com.br/seja-parceiro" }} target="_blank">
      <Button variant="loginPrimary" style={style}>
        {/* <Link to="/parceria">CRIAR CONTA</Link> */}
        CRIAR CONTA
      </Button>
    </Link>
  )
}

export default CreateAccountButton
