import { useMediaQuery } from "react-responsive"

const MediaQueries = () => {
	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-device-width: 1024px)",
	})
	const isBigScreen = useMediaQuery({ query: "(min-device-width: 1600px)" })
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" })
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
	const isTabletOrMobileDevice = useMediaQuery({
		query: "(max-device-width: 1024px)",
	})
	const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
	const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" })
	const isLessThan1300px = useMediaQuery({ query: "(max-width: 1300px)" })

	return {
		isDesktopOrLaptop,
		isBigScreen,
		isTabletOrMobile,
		isMobile,
		isTabletOrMobileDevice,
		isPortrait,
		isRetina,
		isLessThan1300px,
	}
}

export default MediaQueries
