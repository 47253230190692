import React from "react"
import {Link} from 'react-router-dom'

// components
import Button from "../atoms/Button/Button"

const PartnerButton = ({ children, style, onClick, disabled }) => {
  return (
    <Link to={{ pathname: "https://app.empy.com.br/seja-parceiro" }} target="_blank">
      <Button variant="callPrimary" style={style} onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </Link>
  )
}

export default PartnerButton
