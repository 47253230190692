import React from "react"

import HeroImage from "../../assets/images/hero-image.png"
import HeroImageMobile from "../../assets/images/hero-image-mobile.png"

// components
import ReadMoreButton from "../../components/molecules/ReadMoreButton"
import PartnerButton from "../../components/molecules/PartnerButton"
import MediaQueries from "../../utils/MediaQueries"

import styles from "./Hero.module.scss"

const Hero = () => {
  const { isTabletOrMobile, isMobile } = MediaQueries()

  const readMoreButtonStyle = isMobile ? { marginBottom: "10px" } : { marginRight: "27px" }

  return (
    <div className={styles.container} style={{ backgroundImage: !isTabletOrMobile ? `url(${HeroImage})` : `url(${HeroImageMobile})` }}>
      <div className={styles.content}>
          <h1 className={styles.slogan}>
            <p>Crédito<span className={styles.sloganHighlight}>&</span></p> 
            <p>Consignado<span className={styles.sloganHighlight}>&</span></p>
            <p>Fácil<span className={styles.sloganHighlight}>&</span></p>
            <p>Consciente<span className={styles.sloganHighlight}>.</span></p>
          </h1>

        <div className={styles.callButtons}>

          <ReadMoreButton style={readMoreButtonStyle} />
          <PartnerButton>
            {/* <Link to="/parceria">QUERO SER PARCEIRO</Link> */}
              QUERO SER PARCEIRO
          </PartnerButton>
        </div>
      </div>
    </div>
  )
}

// Crédito consciente é com a gente.

export default Hero
