import React from "react"
import MediaQueries from "../../utils/MediaQueries"

// image
import About1Image from "../../assets/images/about1-image.png"
import About2Image from "../../assets/images/about2-image.png"

import styles from "./About.module.scss"

const About = () => {
	const { isTabletOrMobile, isBigScreen } = MediaQueries()

	return (
		<div className={styles.container}>
			<div className={styles.section}>
				<div className={styles.leftContainer}>
					<h1 className={styles.title}>
						<span className={styles.titleHighlightPrimary}>Crédito </span>
						<span className={styles.titleHighlightSecondary}>fácil</span> para seu cliente.
						<p style={{ margin: 10 }} />
						<span className={styles.titleHighlightPrimary}>Vendas </span>
						<span className={styles.titleHighlightSecondary}>rápidas</span> para a sua empresa.
					</h1>
					<div className={styles.description}>
						<h5 className={styles.descriptionTitle}>QUEM SOMOS</h5>
						<p className={styles.descriptionText}>Atuamos como Correspondente Bancário (CORBAN). A Empy está focada em gerar soluções financeiras na modalidade de crédito consignado, intermediando as negociações entre clínica e paciente.</p>
						<p className={styles.descriptionText} style={{ margin: 0 }}>
							A governança da empresa conta com gestores certificados que juntos somam 12 anos de expertise no mercado de crédito consignado.
						</p>
					</div>
				</div>
				{!isBigScreen && <div className={styles.break}></div>}
				<div className={styles.rightContainer}>
					<img src={About1Image} alt="Múltiplas pessoas de mãos unidas" className={`${styles.image} ${styles.image1}`} />
				</div>
			</div>

			<div className={styles.section}>
				{!isTabletOrMobile && (
					<>
						<div className={styles.rightContainer}>
							<img src={About2Image} alt="Homem segurando criança no colo, ambos felizes" className={styles.image} />
						</div>
						{!isBigScreen && <div className={styles.largeBreak}></div>}
					</>
				)}

				<div className={styles.leftContainer}>
					<div className={styles.description}>
						<h5 className={styles.descriptionTitle}>PROBLEMA</h5>
						<p className={styles.descriptionText}>Observamos um problema frequente no mercado de crédito para pessoas físicas desbancarizadas, inadimplentes, sem limite no cartão e que necessitam mais opções de prazo. Esses consumidores acabam não conseguindo contratar soluções para suas necessidades devido à falta de provedores de crédito dispostos a ajudá-los. Do lado do fornecedor do serviço/produto, essa falta de crédito significa perda de vendas ou risco de inadimplência.</p>
					</div>

					<div className={styles.description}>
						<h5 className={styles.descriptionTitle}>SOLUÇÃO</h5>
						<p className={styles.descriptionText} style={{ margin: 0 }}>
							Atuamos como intermediador entre empresa, cliente e banco. Identificamos a situação do cliente e, através do crédito consignado, conseguimos eliminar o risco de inadimplência para a empresa, fornecendo o valor à vista ao prestador sem que o mesmo tenha que pagar taxa de antecipação. Para o cliente, as vantagens são melhor negociação com o pagamento à vista, poder parcelar mesmo não tendo cartão de crédito ou se possuir restrição de crédito, variedades maiores de parcelamento chegando até 96x e menor taxas de juros.
						</p>
					</div>
				</div>

				{isTabletOrMobile && (
					<>
						<div className={styles.rightContainer}>
							<img src={About2Image} alt="Homem segurando criança no colo, ambos felizes" className={`${styles.image} ${styles.image2}`} />
						</div>
						<div className={styles.largeBreak}></div>
					</>
				)}
			</div>
		</div>
	)
}

export default About
