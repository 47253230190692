import React from "react"
// import {isMobile} from 'react-device-detect';

// components
import Hero from "./Hero"
import Mission from "./Mission"
import Benefits from "./Benefits"
import Services from "./Services"

import styles from "./LandingPage.module.scss"

const LandingPage = () => {
  return (
    <div className={styles.container}>
      <Hero />
      <Mission />
      <Benefits />
      <Services />
    </div>
  )
}

export default LandingPage
