import React from "react"
import { Link } from "react-router-dom"

// components
import Button from "../atoms/Button/Button"

const PartnerAreaButton = ({ children, style }) => {
  return (
    <Button variant="loginPrimary" style={style}>
      <Link to="/area-do-parceiro">{children}</Link>
    </Button>
  )
}

export default PartnerAreaButton
