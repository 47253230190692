import React from "react"
import { Link } from "react-router-dom"

import MissionImage from "../../assets/images/mission-image.png"

import styles from "./Mission.module.scss"

const Mission = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={MissionImage} alt="Casal pesquisando no tablet." className={styles.image} />
      </div>
      <div className={styles.textContainer}>
        <h1 className={styles.slogan}>
          <span className={styles.sloganTitle}>Crédito </span>
          <span>de forma fácil e digna é a nossa missão.</span>
        </h1>
        <h5 className={styles.subslogan}>
          <Link to="/cultura">
            <span>Saiba mais</span>
          </Link>
        </h5>
      </div>
    </div>
  )
}

export default Mission
