import React from "react"
import { Link } from "react-router-dom"

// components
import Button from "../atoms/Button/Button"

const ReadMoreButton = ({ style, className }) => {
  return (
    <Link to="/parceria">
      <Button variant="callSecondary" style={style} className={className}>
        SAIBA MAIS
      </Button>
    </Link>
  )
}

export default ReadMoreButton
