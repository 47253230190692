import React from "react"
import { HashLink } from "react-router-hash-link"
import MediaQueries from "../../utils/MediaQueries"

import LockIcon from "../../assets/icons/Lock.svg"

import styles from "./ServicesB2B.module.scss"

const ServicesB2B = () => {
  const { isTabletOrMobile } = MediaQueries()

  return (
    <div className={styles.container}>
      {!isTabletOrMobile && (
        <div className={styles.upperCard}>
          <div className={styles.leftContainer}>
            <h4 className={styles.title}>CRÉDITO PARA SEU CLIENTE, VENDAS PARA SUA EMPRESA</h4>
          </div>
          <div className={styles.rightContainer}>
            <p>Crédito para aumentar as suas vendas. Atendimento rápido e sem burocracia. Processo 100% online, pelo Whatsapp ou telefone. Sem perder tempo em filas e sem perder nenhum nenhum negócio.</p>
          </div>
        </div>
      )}
      <div className={styles.lowerCardsContainer}>
        {isTabletOrMobile && (
          <HashLink to="/path#products">
            <div className={styles.lowerCard}>
              <div className={styles.leftContainer}>
                <h4 className={styles.title}>CRÉDITO PARA SEU CLIENTE, VENDAS PARA SUA EMPRESA</h4>
              </div>
              <div className={styles.rightContainer}>
                <p>Crédito para aumentar as suas vendas. Atendimento rápido e sem burocracia. Processo 100% online, pelo Whatsapp ou telefone. Sem perder tempo em filas e sem perder nenhum nenhum negócio.</p>
              </div>
            </div>
          </HashLink>
        )}
        <HashLink to="/path#products">
          <div className={styles.lowerCard}>
            <div className={styles.icon}>
              <img src={LockIcon} alt="Cadeado" />
            </div>
            <p className={styles.lowerCardText}>Serviço exclusivo para parceiros</p>
          </div>
        </HashLink>
        <HashLink to="/path#products" smooth scroll={el => el.scrollIntoView(0, 0)}>
          <div className={styles.lowerCard}>
            <div className={styles.icon}>
              <img src={LockIcon} alt="Cadeado" />
            </div>
            <p className={styles.lowerCardText}>Serviço exclusivo para parceiros</p>
          </div>
        </HashLink>
      </div>
    </div>
  )
}

export default ServicesB2B
