import React, { useState, useEffect } from "react"
import axios from "axios"
import MediaQueries from "../../utils/MediaQueries"

// icons
import MapPin from "../../assets/icons/MapPin.svg"
import Phone from "../../assets/icons/Phone.svg"
import WhatsappLogo from "../../assets/icons/WhatsappLogo.svg"
import At from "../../assets/icons/At.svg"

// components
import ContactForm from "./ContactForm"
import Button from "../../components/atoms/Button/Button"

import styles from "./Contact.module.scss"

const Contact = () => {
	const [form, setForm] = useState()
	const [formComplete, setFormComplete] = useState(false)
	const [loading, setLoading] = useState(false)
	const [formSent, setFormSent] = useState(false)

	const { isBigScreen } = MediaQueries()

	useEffect(() => {
		if (form) {
			if (form?.name && form?.message && form?.email && form?.phone) {
				console.log("form complete!")
				setFormComplete(true)
			} else {
				setFormComplete(false)
			}
		}
	}, [form])

	const handleFormSubmit = e => {
		const service_id = "service_50ydn3e"
		const template_id = "template_7b4dy32"
		const user_id = "user_RV5M2mhLXPY2qa7m9XnBp"
		setLoading(true)
		axios
			.post("https://api.emailjs.com/api/v1.0/email/send", {
				service_id,
				template_id,
				user_id,
				template_params: {
					name: form.name,
					message: form.message,
					email: form.email,
					phone: form.phone,
				},
			})
			.then(function (response) {
				console.log(response)
				setForm(null)
				setLoading(false)
				window.scroll(0, 0, {
					behavior: "smooth",
				})
				setFormSent(true)
			})
			.catch(function (error) {
				console.log(error)
				setLoading(false)
				setForm(null)
			})
	}

	const handleNewRequest = () => {
		window.scroll(0, 0, {
			behavior: "smooth",
		})
		setForm(null)
		setFormSent(false)
	}

	const content = [
		{
			icon: MapPin,
			groups: [
				{
					title: "localização",
					description: ["Av. Higienópolis, 1601 – Térreo – Conj. 15 – Sala Paris", "Centro – Londrina – PR", "CEP: 86020-080"],
				},
			],
		},
		{
			icon: Phone,
			groups: [
				{
					title: "telefone",
					description: ["(43) 3024-1570"],
				},
			],
		},
		{
			icon: WhatsappLogo,
			groups: [
				{
					title: "whatsapp",
					description: ["(43) 99941-9203"],
				},
				{
					title: "whatsapp empresas",
					description: ["(43) 98423-6803"],
				},
			],
		},
		{
			icon: At,
			groups: [
				{
					title: "e-mail",
					description: ["contato@empank.com.br"],
				},
			],
		},
	]

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer} style={{ maxWidth: isBigScreen && "600px" }}>
				<div className={styles.contactsContainer}>
					{content.map(contact => {
						return (
							<div className={styles.contactContainer} key={contact.groups[0].title}>
								<div className={styles.iconContainer}>
									<img src={contact.icon} alt={contact.groups[0].title} />
								</div>
								<div>
									{contact.groups.map(group => {
										return (
											<div className={styles.groupContainer} key={group.title}>
												<h1 className={styles.groupTitle}>{group.title.toUpperCase()}</h1>
												<div className={styles.groupDescription}>
													{group.description.map(paragraph => {
														return (
															<p className={styles.paragraph} key={paragraph.slice(0, 10)}>
																{paragraph}
															</p>
														)
													})}
												</div>
											</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
			</div>
			<div className={styles.break}></div>
			<div className={styles.rightContainer} style={{ maxWidth: isBigScreen && "600px" }}>
				{formSent ? (
					<div className={styles.formContainer} style={{ minHeight: "auto" }}>
						<h1 className={styles.formTitle} style={{ marginBottom: 5 }}>
							Mensagem enviada!
						</h1>
						<p className={styles.formDescription}>Retornaremos o mais breve possível.</p>
						<Button variant="loginPrimary" onClick={handleNewRequest} className="w-100 mt-5">
							Enviar mais uma mensagem
						</Button>
					</div>
				) : (
					<div className={styles.formContainer}>
						<h1 className={styles.formTitle}>Contato</h1>
						<p className={styles.formDescription}>Responderemos o seu e-mail em até 24 horas.</p>

						<ContactForm form={form} setForm={setForm} handleFormSubmit={handleFormSubmit} id="contactForm" />

						<Button variant="buyBtn" form="contactForm" type="submit" disabled={!formComplete} style={{ width: "100%" }}>
							{!loading ? "Enviar" : "Carregando..."}
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

export default Contact
